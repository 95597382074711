import React from 'react';

const Footer = () => {
    return (
        <div>
            <footer id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <div className="copyrights">
                                <p>&copy; {new Date().getFullYear()} Quiero Recordings.</p>
                                <p>Made with <i className="ion-heart"></i> by <a href="http://luismexin.me" rel="noopener noreferrer" target="_blank">luismexin.me</a></p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="footer-socials">
                                <a href="https://www.facebook.com/quierorecordings/" rel="noopener noreferrer" target="_blank">Facebook</a>
                                <a href="https://quiero-recordings.bandcamp.com/" rel="noopener noreferrer" target="_blank">BandCamp</a>
                                <a href="https://soundcloud.com/quiero-recordings" rel="noopener noreferrer" target="_blank">SoundCloud</a>
                                <a href="https://www.mixcloud.com/quierorecordingspodcast" rel="noopener noreferrer" target="_blank">MixCloud</a>
                                <a href="https://www.residentadvisor.net/record-label.aspx?id=12264" rel="noopener noreferrer" target="_blank">RA</a>
                                <a href="https://www.instagram.com/quierorecordings/" rel="noopener noreferrer" target="_blank">Instagram</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
