import {RectShape, TextBlock} from "react-placeholder/lib/placeholders";
import ReactPlaceholder from "react-placeholder";

const PlayerPlaceholder = () => {
    const component = (
        <div className="row">
            <div className="float-left">
                <RectShape color='#E0E0E0' style={{width: 135, height: 180}}/>
            </div>
            <div className="col float-left">
                <div className="mt-2 mb-2 ml-0 mr-5">
                    <TextBlock color='#E0E0E0' rows={3}/>
                    <br/>
                    <RectShape color='#E0E0E0' style={{width: '100%', height: 25}}/>
                </div>
            </div>
        </div>
    )
    return (
        <ReactPlaceholder
            showLoadingAnimation
            customPlaceholder={component}
            ready={false}>
            <p>Content Placeholder</p>
        </ReactPlaceholder>
    )
};

export default PlayerPlaceholder;