import React from 'react';
import bg from '../../images/bg/1.png';
import releasesMp4 from '../../video/releases.mp4';
import releasesOgv from '../../video/releases.ogv';
import releasesWebm from '../../video/releases.webm';
import podcastsMp4 from '../../video/podcasts.mp4';
import podcastsOgv from '../../video/podcasts.ogv';
import podcastsWebm from '../../video/podcasts.webm';

const bgStyle = {
    background: `url(${bg})`,
    top: '30%', left: '30%',
};

const arrow = {
    position: 'absolute',
    bottom: '0',
    left: '50%',
    margin: '100px auto',
};

const Hero = (props) => {
    const { type } = props;
    let video;
    switch (type) {
        case 'releases':
            video = {
                mp4: releasesMp4,
                ogv: releasesOgv,
                webm: releasesWebm,
            };
            break;
        case 'podcasts':
            video = {
                mp4: podcastsMp4,
                ogv: podcastsOgv,
                webm: podcastsWebm,
            };
            break;
        default:
            video = {
                mp4: releasesMp4,
                ogv: releasesOgv,
                webm: releasesWebm,
            };
            break;
    }
    return (
        <div className="hero lg no-pad-top d-none d-sm-none d-md-none d-lg-block d-xl-block">
            <div className="row">
                <div className="col-sm-12">
                    <div id="hero-slider" className="owl-carousel owl-theme">
                        <div className="item">
                            <video id="background-video" playsInline preload="auto" loop autoPlay muted>
                                <source src={video.mp4} type="video/mp4"/>
                                <source src={video.ogv} type="video/ogv"/>
                                <source src={video.webm} type="video/webm"/>
                                Your browser does not support the video tag.
                            </video>
                            <div style={arrow}>
                                <i className="ion-chevron-down"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
