import {RectShape} from "react-placeholder/lib/placeholders";
import ReactPlaceholder from "react-placeholder";

const ArtworkPlaceholder = ({active}) => {
    const component = (
        <RectShape color='#E0E0E0' style={{width: 348, height: 348}}/>
    )
    if (active) {
        return (
            <ReactPlaceholder
                showLoadingAnimation
                customPlaceholder={component}
                ready={false}>
                <p>Content Placeholder</p>
            </ReactPlaceholder>
        )
    }
    return null;
};

export default ArtworkPlaceholder;