import React from 'react';
import Typist from 'react-typist';
import video from '../../video/error.mp4';

const Error = () => {
    return (
        <div className="hero lg no-pad-top">
            <div className="row">
                <div className="col-sm-12">
                    <div id="hero-slider" className="owl-carousel owl-theme d-none d-sm-none d-md-none d-lg-block d-xl-block">
                        <div className="item">
                            <video id="background-video" preload="auto" loop autoPlay muted playsInline>
                                <source src={video} type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                            <div className="caption">
                                <div className="container">
                                        <h1>
                                            <Typist>
                                                <strong>Error</strong><br/>Page not found!
                                            </Typist>
                                        </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Error;
