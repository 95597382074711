import React from 'react';
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';
import aboutMp4 from '../../video/about.mp4';
import aboutOgv from '../../video/about.ogv';
import aboutWebm from '../../video/about.webm';

const arrow = {
    position: 'absolute',
    bottom: '0',
    left: '50%',
    margin: '100px auto',
};

const hero = {
    position: 'relative',
    bottom: '0',
    left: '0',
    height: '50vh',
    width: '100%',
    overflow: 'hidden',
};

const pageTitle = {
    paddingTop: '50px',
};

const Us = () => {
    return (
        <div>
            <div id="hero-slider" className="d-none d-sm-none d-md-none d-lg-block d-xl-block" style={hero}>
                <video id="background-video-us" playsInline preload="auto" loop autoPlay muted>
                    <source src={aboutMp4} type="video/mp4"/>
                    <source src={aboutOgv} type="video/ogv"/>
                    <source src={aboutWebm} type="video/webm"/>
                    Your browser does not support the video tag.
                </video>
                <div style={arrow}>
                    <i className="ion-chevron-down"/>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title has-description" style={pageTitle}>
                            <Typist>
                                <h1>About Us</h1>
                                <p>We are a latin american record label</p>
                            </Typist>
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div className="contact-info">
                            <div className="clearfix mar-top-30">
                                <div className="icon">
                                    <i className="ion-ios-email-outline"></i>
                                </div>
                                <div className="details">
                                    <h3>Demos</h3> demos<b>[at]</b>quiero-recordings.com
                                </div>
                            </div>
                            <div className="clearfix mar-top-30">
                                <div className="icon">
                                    <i className="ion-ios-email-outline"></i>
                                </div>
                                <div className="details">
                                    <h3>A & R</h3> leo<b>[at]</b>quiero-recordings.com
                                </div>
                            </div>
                            <div className="clearfix mar-top-30">
                                <div className="icon">
                                    <i className="ion-ios-email-outline"></i>
                                </div>
                                <div className="details">
                                    <h3>Contact</h3> hello<b>[at]</b>quiero-recordings.com
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Us;
