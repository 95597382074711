import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import logoMobile from '../../images/logoMobile.png';

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sideBar: '',
            iconState: 'menu-open'
        };
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        const sideBar = this.state.sideBar === '' ? 'flexnav-show' : '';
        const iconState = this.state.iconState === 'menu-close' ? 'menu-open' : 'menu-close';
        this.setState({ sideBar, iconState });
    };

    getSideBarState() {
        return this.state;
    };

    render() {
        return (
            <div>
                <div className="menubar off-screen">
                    <div id="logo">
                        <Link to="/">
                            <img className="d-none d-sm-none d-md-none d-lg-block d-xl-block" src={logo} alt="Logo" />
                            <img className="d-block d-sm-block d-md-block d-lg-none d-xl-none" src={logoMobile} alt="Logo" />
                        </Link>
                    </div>
                    <nav>
                        <div className={`menu-button active sidebar ${this.getSideBarState().iconState}`} onClick={this.toggleMenu}>
                            <i className="open-icon ion-android-menu"></i>
                            <i className="close-icon ion-android-close"></i>
                        </div>
                        <ul className={`flexnav sidebar with-js opacity sm-screen ${this.getSideBarState().sideBar}`} data-breakpoint="1920">
                            <li>
                                <Link to="/" onClick={this.toggleMenu}>Releases</Link>
                            </li>
                            <li>
                                <Link to="/podcasts" onClick={this.toggleMenu}>Podcasts</Link>
                            </li>
                            <li>
                                <Link to="/us" onClick={this.toggleMenu}>Us</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }
}

NavBar.propTypes = {};

export default NavBar;

