import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';

const Iframe = (props) => {
    const IframeElem = useRef(null);

    useEffect(() => {
        let iframe = ReactDOM.findDOMNode(IframeElem.current);
        iframe.addEventListener('load', props.onLoad);
    }, []);

    const iframeStyle = {
        width: '100%',
        height: '120px',
        border: '0',
        position: 'absolute',
    };

    return (
        <iframe
            id="iframePlayer"
            ref={IframeElem}
            {...props}
            frameBorder={'0'}
            width={'100%'}
            height={'120px'}
            style={iframeStyle}
            seamless={true}
        />
    )
}

export default Iframe