import React, {useState} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import ReactGA from 'react-ga';
import NavBar from '../components/navbar/NavBar';
import Releases from '../containers/releases/Releases';
import Podcasts from '../containers/podcasts/Podcasts';
import Us from '../containers/us/Us';
import Error from '../containers/error/Error';
import Footer from '../components/footer/Footer';
import Player from '../components/player/Player';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/magnific-popup.css';
import '../styles/ionicons.min.css';
import '../styles/style.css';

ReactGA.initialize('UA-55157751-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
    const [playerData, setPlayerData] = useState(null);
    const [playerLoaded, setPlayerLoaded] = useState(false);

    const getPlayerData = (data) => {
        let player;
        // set loaded to false to show placeholder while its loading
        setPlayerLoaded(false);
        if (data.type === 'release') {
            player = `https://bandcamp.com/EmbeddedPlayer/album=${data.key}/size=large/bgcol=333333/linkcol=0f91ff/tracklist=false/artwork=small/track=2723758019/transparent=true/`;
        } else {
            player = `https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fquierorecordingspodcast%2F${data.key}%2F`;
        }
        setPlayerData(player);
    };

    const playerHasLoaded = () => {
        setPlayerLoaded(true);
    };

    const pageWrapper = {
        marginBottom: '120px',
    };
    playerData === null ? pageWrapper.marginBottom = '0' : pageWrapper.marginBottom = '120px';
    return (
        <Router>
            <div id="page-wrapper" style={pageWrapper}>
                <NavBar/>
                <Switch>
                    <Route exact path="/" render={() => <Releases getPlayer={getPlayerData}/>}/>
                    <Route path="/podcasts" render={() => <Podcasts getPlayer={getPlayerData}/>}/>
                    <Route path="/us" component={Us}/>
                    <Route component={Error}/>
                </Switch>
                <Footer/>
                <Player data={playerData} onLoad={playerHasLoaded} loaded={playerLoaded}/>
            </div>
        </Router>
    );
}

export default App;
