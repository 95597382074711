import React from 'react';
import Iframe from '../player-iframe/Player-iframe';
import PlayerPlaceholder from "../placeholders/PlayerPlaceholder";

const Player = (props) => {
    const { data, onLoad, loaded } = props;
    return (
        <div>
            <div className={`player ${data === null ? '' : 'player-show'}`}>
                {!loaded && <PlayerPlaceholder />}
                <Iframe src={data} onLoad={onLoad} />
            </div>
        </div>
    );
};

export default Player;
