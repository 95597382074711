import React, {useState, useEffect} from 'react';
import Typist from 'react-typist';
import Hero from '../../components/hero/Hero';
import ArtworkPlaceholder from "../../components/placeholders/ArtworkPlaceholder";
import Error from "../../components/misc/Error";
import {Link} from 'react-router-dom';
import {getCloudCasts} from '../../api/mixcloudApi';

const Podcasts = (props) => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [podcasts, setPodcasts] = useState([]);

    // initialize our dummy data programmatically
    const dummyArray = Array(9).fill(null).map((elem, i) => {
        return {id: i, data: 'dummy'};
    });

    useEffect(() => {
        getCloudCasts()
            .then(releases => {
                setPodcasts(releases);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setError(true);
            })
    }, []);

    return (
        <div>
            <Hero type='podcasts'/>
            <div id="content-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="portfolio-wrap mar-top-100">
                                <div className="filters">
                                    <h1>
                                        <Typist>
                                            Podcasts
                                        </Typist>
                                    </h1>
                                </div>
                                <div id="portfolio-container"
                                     className="portfolio-container portfolio-overlay dark text-overlay row isotope">
                                    {loading && dummyArray.map((elem) =>
                                        <div key={elem.id} className="portfolio-item col-md-4 webdesign">
                                            <ArtworkPlaceholder active={loading}/>
                                        </div>
                                    )}
                                    {!loading && podcasts.map(podcast =>
                                        <div key={podcast.url} className="portfolio-item col-md-4 webdesign">
                                            <Link to='/podcasts' onClick={() => {
                                                props.getPlayer({type: 'podcast', key: podcast.slug})
                                            }}>
                                                <div className="portfolio-content">
                                                    <div className="portfolio-img-content">
                                                        <img src={podcast.pictures.large}
                                                             alt=""/>
                                                    </div>
                                                    <div className="portfolio-text-content">
                                                        <div className="portfolio-text">
                                                            <h3>{podcast.name}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )}
                                    {error && <Error/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Podcasts;
