import axios from 'axios';

const mixCloudUrl = 'https://api.mixcloud.com/quierorecordingspodcast';

const getCloudCasts = async () => {
    const url = `${mixCloudUrl}/cloudcasts/?limit=50`;
    try {
        const cloudCasts = await axios.get(url);
        return cloudCasts.data.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export { getCloudCasts };
