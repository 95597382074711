import Typist from "react-typist";

const Error = () => {
    return (
        <div className="caption">
            <div className="container">
                <h1>
                    <Typist>
                        <strong className="text-danger">Error</strong>
                    </Typist>
                </h1>
                <h4>
                    <Typist>
                        Couldn't load content, Woopsy!
                    </Typist>
                </h4>
            </div>
        </div>
    )
};

export default Error;