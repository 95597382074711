import axios from 'axios';

let backendUrl;

if (process.env.NODE_ENV === 'development') {
    backendUrl = 'http://localhost:3010/albums';
} else {
    backendUrl = 'http://bs.quiero-recordings.com/albums';
}

const HARD_CODED_ALBUMS = [
    { "id": "3880225988", "url": "/album/dejarlo-ser", "image": "https://f4.bcbits.com/img/a2590041716_2.jpg", "title": "Dejarlo Ser", "artist": "GHIONE" },
    { "id": "2817086250", "url": "/album/desierto-incierto", "image": "https://f4.bcbits.com/img/a2095250837_2.jpg", "title": "DESIERTO INCIERTO", "artist": "Compilation X" },
    { "id": "1100438731", "url": "/album/locuras", "image": "https://f4.bcbits.com/img/a1393137872_2.jpg", "title": "LOCURAS", "artist": "GHIONE" },
    { "id": "4071148702", "url": "/album/compilado-8", "image": "https://f4.bcbits.com/img/a3398991726_2.jpg", "title": "COMPILADO #8", "artist": "V.A" },
    { "id": "2814496170", "url": "/album/compilado-7", "image": "https://f4.bcbits.com/img/a1186051087_2.jpg", "title": "COMPILADO  #7", "artist": "V.A" },
    { "id": "956184753", "url": "/album/espacio-tiempo", "image": "https://f4.bcbits.com/img/a2202166444_2.jpg", "title": "Espacio / Tiempo", "artist": "Cristian Monak" },
    { "id": "726341372", "url": "/album/compilado-6-night", "image": "https://f4.bcbits.com/img/a1633179606_2.jpg", "title": "Compilado  #6 Night", "artist": "V.A" },
    { "id": "2698971840", "url": "/album/compilado-6-day", "image": "https://f4.bcbits.com/img/a3358406204_2.jpg", "title": "Compilado #6 Day", "artist": "V.A" },
    { "id": "740410920", "url": "/album/supersonic-ocean", "image": "https://f4.bcbits.com/img/a2544557833_2.jpg", "title": "Supersonic Ocean", "artist": "Nørus" },
    { "id": "480297735", "url": "/album/confusa-mente-clara", "image": "https://f4.bcbits.com/img/a2813898672_2.jpg", "title": "Confusa Mente Clara", "artist": "Gobbi" },
    { "id": "1021442310", "url": "/album/nav-o-ep", "image": "https://f4.bcbits.com/img/a3744723704_2.jpg", "title": "Navío ep", "artist": "Ibu Selva" },
    { "id": "432766409", "url": "/album/cambiante-ep", "image": "https://f4.bcbits.com/img/a2161404386_2.jpg", "title": "Cambiante ep", "artist": "Leónidas Ghione" },
    { "id": "1898630235", "url": "/album/novas-raizes-2", "image": "https://f4.bcbits.com/img/a0342553888_2.jpg", "title": "Novas Raizes", "artist": "Table Daddy" },
    { "id": "4028337092", "url": "/album/ep-i", "image": "https://f4.bcbits.com/img/a3436673424_2.jpg", "title": "Ep I", "artist": "JO A O" },
    { "id": "3395157845", "url": "/album/compilado-5", "image": "https://f4.bcbits.com/img/a3592506192_2.jpg", "title": "COMPILADO #5", "artist": "Various Artist" },
    { "id": "3534632002", "url": "/album/compilado-4", "image": "https://f4.bcbits.com/img/a0553682601_2.jpg", "title": "Compilado #4\n        ", "artist": "V. A" }
];

const getQuieroAlbums = async () => {
    // try {
    //     const albums = await axios.get(backendUrl);
    //     return albums.data;
    // } catch (e) {
    //     console.error(e);
    //     throw e;
    // }
    return HARD_CODED_ALBUMS;
};

const getQuieroAlbum = async (slug) => {
    try {
        const album = await axios.get(`http://localhost:3010/album/${slug}`)
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export { getQuieroAlbums };
